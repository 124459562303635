body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('../public/images/LegislaturadelEdomex.6b4939bc.jpg'); */
  background: linear-gradient(87deg, rgba(23, 44, 79, 0.8), rgba(26, 23, 77, 0.8)), url(../public/images/LegislaturadelEdomex.6b4939bc.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-color: #F3F4F6 !important;
  scrollbar-width: thin;
  scrollbar-color: #90A4AE #CFD8DC;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: #212145 ;
  border-radius: 4px;
  border: 1px solid #0268CA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Float button on rigth bottom side */
.fab_add {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

*:focus {
  outline: none;
}