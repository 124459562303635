.error-container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.error-container div, .error-container a {
  width: 100%;
  text-align: center;
}

.error-container .number {
  position: relative;
  font: 900 30vmin "Consolas";
  letter-spacing: 5vmin;
  text-shadow: 2px -1px 0 #000, 4px -2px 0 #0a0a0a, 6px -3px 0 #0f0f0f, 8px -4px 0 #141414, 10px -5px 0 #1a1a1a, 12px -6px 0 #1f1f1f, 14px -7px 0 #242424, 16px -8px 0 #292929;
}

.error-container .number::before {
  background-color: #212145;
  width: 100%;
  height: 100%;
  mix-blend-mode: screen;
  display: block;
  position: absolute;
  content: "";
}

.error-container .text {
  font: 400 4vmin "Consolas";
  color: #ffffff;
}

.error-container .text span {
  font-size: 9vmin;
}

.error-container .return {
  margin-top: 16px;
  font: 4vmin "Consolas";
  color: #ffffff;
  text-decoration: none;
}

.error-container .return:active {
  color: #ffffff;
}